import { get, keys } from 'lodash-es'
import Swal from 'sweetalert2'

export const useUi = defineStore('ui', () => {
  const selectedCertification = ref('senior-developer')

  const isAuthModalOpen = ref(false)

  const isPresale = ref(false)

  const flashMessageKey = useCookie('flashMessageKey')

  const redirectUrl = useCookie('redirectUrl', { maxAge: 60 })

  const flashMessages = reactive({
    CERT_INTEREST_SUCCESS: {
      icon: 'success',
      title: 'Done!',
      html: 'Get ready for the latest news and updates on this certification.<br><br>Keep an eye on your inbox 😉',
      confirmButtonText: 'Close'
    },
    CERT_SIGN_UP_COMPLETED: {
      icon: 'success',
      title: 'Sign up completed!',
      html: 'Thank you for creating an account.<br><br>Get ready for the latest news and updates on our certifications.<br><br>Keep an eye on your inbox 😉',
      confirmButtonText: 'Close'
    },
    VUEJS_SIGN_UP_COMPLETED: {
      icon: 'success',
      title: 'You are in!',
      html: `Thank you for creating an account. 
          <br><br> Get ready to be part of the first batch of Officially Certified Vue.js Developers! 
          <br><br> Don't forget to cast your vote for future certifications you would like to see. 
          <br><br> Thank you so much for your support. 
          <br><br> Please provide us your Social Media URLs to share your success on Social Media.`,
      confirmButtonText: 'Close'
    },
    NUXT_SIGN_UP_COMPLETED: {
      icon: 'success',
      title: 'You are in!',
      html: `Thank you for creating an account. 
        <br><br> Get ready to be part of the first batch of Officially Certified Nuxt Developers! 
        <br><br> Don't forget to cast your vote for future certifications you would like to see.
        <br><br> Thank you so much for your support. 
        <br><br> Please provide us your Social Media URLs to share your success on Social Media.`,
      confirmButtonText: 'Close'
    },
    ANGULAR_SIGN_UP_COMPLETED: {
      icon: 'success',
      title: 'You are in!',
      html: `Thank you for creating an account. 
        <br><br> Get ready to be part of the first batch of Officially Certified Angular Developers! 
        <br><br> Don't forget to cast your vote for future certifications you would like to see. 
        <br><br> Thank you so much for your support. 
        <br><br> Please provide us your Social Media URLs to share your success on Social Media.`,
      confirmButtonText: 'Close'
    },
    JAVASCRIPT_SIGN_UP_COMPLETED: {
      icon: 'success',
      title: 'You are in!',
      html: `Thank you for creating an account. 
        <br><br> Get ready to be part of the first batch of Officially Certified JavaScript Developers! 
        <br><br> Don't forget to cast your vote for future certifications you would like to see. 
        <br><br> Thank you so much for your support. 
        <br><br> Please provide us your Social Media URLs to share your success on Social Media.`,
      confirmButtonText: 'Close'
    }
  })

  function openAuthModal () {
    isAuthModalOpen.value = true
  }

  function closeAuthModal () {
    isAuthModalOpen.value = false
  }

  function displayFlashMessage () {
    const message = flashMessageKey.value ? flashMessages[flashMessageKey.value] : null
    if (!message) return
    Swal.fire(flashMessages[flashMessageKey.value])
    flashMessageKey.value = null
  }

  function showErrorModal (e, text = '', rest = {}) {
    const errors = get(e, 'response._data.errors')

    if (!errors) {
      return Swal.fire({
        title: 'Error',
        text,
        icon: 'error',
        ...rest
      })
    }

    const errorKeys = keys(errors)
    const errorKey = get(errorKeys, '0')
    const message = get(errors, `${errorKey}.0`) || get(e, 'response._data.message', text)

    return Swal.fire({
      title: 'Error',
      text: message,
      icon: 'error',
      ...rest
    })
  }

  return {
    selectedCertification,
    redirectUrl,
    isPresale,
    flashMessageKey,
    isAuthModalOpen,
    openAuthModal,
    closeAuthModal,
    displayFlashMessage,
    showErrorModal
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUi, import.meta.hot))
}
