<script setup>
import { XMarkIcon } from '@heroicons/vue/24/outline'

const now = new Date()
const config = useRuntimeConfig()
const route = useRoute()

const { data } = await useFetch(config.public.BANNER_JSON_URL, {
  query: { affiliate: 'certificates_js' },
  getCachedData (key) {
    return useNuxtData(key).data.value
  }
})

const phase = data.value?.flat()
  .map((item) => ({
    ...item,
    dateString: item.ends,
    remaining: new Date(item.ends) - now,
    header: stylize(item.header),
    title: stylize(item.title),
    prelude: stylize(item.prelude),
    subtitle: stylize(item.subtitle)
  }))
  .find(item => item.remaining > 0)

function stylize (string) {
  return string?.replace('[', '<span class="text-still-yellow">').replace(']', '</span>')
}

const showBanner = useCookie('banner-javascript',
  {
    default: () => true,
    watch: 'shallow',
    maxAge: 60 * 60 * 24 * 7,
    SameSite: 'none'
  }
)

const showBannerOnLanding = computed(() => {
  if (route.path.includes('/javascript')) {
    return true
  } else {
    return phase.showOnHomepage || false
  }
})

const currentTheme = computed(() => {
  return phase?.theme || 1
})
</script>

<template>
  <div
    v-show="showBanner && phase && showBannerOnLanding"
    class="dark group relative flex h-12 w-full items-center justify-center gap-3 bg-still-yellow/20 px-2 backdrop-blur-2xl md:gap-5 md:px-6 xl:gap-10"
    :class="{'!h-[50px] md:!h-12' : currentTheme === 3}">
    <NuxtLink
      to="/javascript#pricing"
      :target="!route.path.includes('/javascript') ? '_blank' : '_self'"
      class="dark w-full">
      <div
        v-if="currentTheme === 1"
        class="flex items-center justify-center gap-2 md:gap-5 xl:gap-10">
        <div
          class="hidden items-center gap-2 md:flex md:gap-4">
          <div
            class="text-xs font-bold text-white md:block md:text-sm xl:text-base"
            v-html="!route.path.includes('/javascript') ? stylize(phase?.prelude) : stylize(phase?.homePrelude)" />
        </div>
        <div class="flex flex-row items-center gap-2 md:flex-row md:gap-5">
          <div class="flex flex-col items-center xl:flex-row xl:gap-8">
            <div
              class="whitespace-nowrap text-center text-[10px] font-bold text-white md:hidden  md:text-[12px] xl:text-base"
              v-html="!route.path.includes('/javascript') ? stylize(phase?.prelude) : stylize(phase?.homePrelude)" />
            <div
              v-if="phase?.header || phase?.homeHeader"
              class="flex !flex-row items-center gap-4 text-center text-[10px] font-semibold text-white md:w-auto md:whitespace-nowrap md:text-xs xl:text-base">
              <span>
                <InlineSvg
                  src="/images/fire-js.svg"
                  class="h-7 w-auto"
                  :class="{ 'hidden md:block': $route.path === '/'}" /></span>
              <span v-html="!route.path.includes('/javascript') ? stylize(phase?.header) : stylize(phase?.homeHeader || phase?.header)" />
            </div>
          </div>
          <div class="flex flex-col items-center gap-0 md:flex-row md:gap-2 lg:gap-0">
            <p
              v-if="phase?.preAddendum"
              class="rounded-md text-center text-[8px] font-bold uppercase text-white md:text-[10px] lg:bg-still-yellow/20 lg:px-4 lg:py-1">
              {{ phase?.preAddendum }}
            </p>
            <div
              v-if="phase?.addendum"
              class="text-center text-[10px] font-bold text-white md:text-[12px]  lg:px-4 lg:py-1 lg:text-lg"
              style=""
              v-html="phase?.addendum" />
          </div>
          <div
            v-if="phase?.showCountdown"
            class="flex flex-col items-center md:flex-row md:gap-4">
            <span
              v-if="phase?.isExtended"
              class="whitespace-nowrap text-[8px] font-bold uppercase text-still-yellow md:text-[12px] md:tracking-widest xl:mr-9">
              EXTENDED
            </span>
            <span class="text-gradient-primary whitespace-nowrap text-[8px] font-bold uppercase md:text-[9px] md:tracking-widest">
              {{ phase?.countdownFooter }}
            </span>
            <AppCountdown
              v-bind="{ remaining: phase?.remaining, daysToHours: phase?.showDaysAsHours }"
              class="dark"
              box-class="text-white lg:scale-[1.2] lg:translate-x-2 md:px-2 md:py-1 md:bg-still-yellow/30" />
          </div>
        </div>
      </div>
      <div
        v-if="currentTheme === 2"
        class="flex items-center justify-center gap-2 md:gap-5 xl:gap-10">
        <div
          class=" items-center gap-2 md:flex md:gap-4">
          <span>
            <InlineSvg
              src="/images/fire-js.svg"
              class="h-6 w-auto md:h-7" /></span>
          <div
            class="hidden text-xs font-bold text-white md:block md:text-sm xl:text-base"
            v-html="!route.path.includes('/javascript') ? stylize(phase?.prelude) : stylize(phase?.homePrelude)" />
        </div>
        <div class="flex flex-row items-center gap-2 md:flex-row md:gap-5">
          <div class="flex flex-row items-center gap-4 xl:flex-row xl:gap-8">
            <div
              class="whitespace-nowrap text-center text-[10px] font-bold text-white md:hidden  md:text-[12px] xl:text-base"
              v-html="!route.path.includes('/javascript') ? stylize(phase?.prelude) : stylize(phase?.homePrelude)" />
            <div
              v-if="phase?.header || phase?.homeHeader"
              class="flex !flex-row items-center gap-4 text-center text-[10px] font-semibold text-white md:w-auto md:whitespace-nowrap md:text-xs xl:text-base">
              <span v-html="!route.path.includes('/javascript') ? stylize(phase?.header) : stylize(phase?.homeHeader || phase?.header)" />
            </div>
            <div
              v-if="phase?.subtitle"
              class="flex !flex-row items-center gap-4 text-center text-[10px] font-semibold text-white md:w-auto md:whitespace-nowrap md:text-xs xl:text-base">
              <span v-html="phase?.subtitle" />
            </div>
          </div>
          <div class="flex flex-col items-center gap-0 md:flex-row md:gap-2 lg:gap-0">
            <p
              v-if="phase?.preAddendum"
              class="rounded-md text-center text-[8px] font-bold uppercase text-white md:text-[10px] lg:bg-still-yellow/20 lg:px-4 lg:py-1">
              {{ phase?.preAddendum }}
            </p>
            <div
              v-if="phase?.addendum"
              class="text-center text-[9px] font-bold tracking-wide text-white md:text-[12px]  lg:px-4 lg:py-1 lg:text-[12px]"
              style=""
              v-html="phase?.addendum" />
          </div>
          <div
            v-if="phase?.showCountdown"
            class="flex flex-col items-center md:flex-row md:gap-4">
            <span
              v-if="phase?.isExtended"
              class="whitespace-nowrap text-[8px] font-bold uppercase text-still-yellow md:text-[12px] md:tracking-widest xl:mr-9">
              EXTENDED
            </span>
            <span class="text-gradient-primary whitespace-nowrap text-[8px] font-bold uppercase md:text-[9px] md:tracking-widest">
              {{ phase?.countdownFooter }}
            </span>
            <AppCountdown
              v-bind="{ remaining: phase?.remaining, daysToHours: phase?.showDaysAsHours }"
              class="dark"
              box-class="text-white lg:scale-[1.2] lg:translate-x-2 md:px-2 md:py-1 md:bg-still-yellow/30" />
          </div>

          <div
            v-if="phase?.cta"
            class="hidden h-[30px] items-center justify-center rounded-md bg-still-yellow px-4 text-sm font-bold text-eerie-black transition-colors duration-100 ease-linear group-hover:bg-white md:inline-flex">
            {{ phase?.cta }}
          </div>
        </div>
      </div>
      <div
        v-if="currentTheme === 3"
        class="flex items-center justify-center gap-2 md:gap-5 xl:gap-10">
        <div
          class=" items-center gap-2 md:flex md:gap-4">
          <span>
            <InlineSvg
              src="/images/fire-js.svg"
              class="h-6 w-auto md:h-7" /></span>
          <div
            class="hidden text-xs font-bold text-white md:block md:text-sm xl:text-base"
            v-html="!route.path.includes('/javascript') ? stylize(phase?.prelude) : stylize(phase?.homePrelude)" />
        </div>
        <div class="flex flex-row items-center gap-2 md:flex-row md:gap-5">
          <div class="flex flex-col items-center md:flex-row md:gap-4 xl:gap-8">
            <div
              class="whitespace-nowrap text-center text-[9px] font-bold text-white md:hidden  md:text-[12px] xl:text-base"
              v-html="!route.path.includes('/javascript') ? stylize(phase?.prelude) : stylize(phase?.homePrelude)" />
            <div
              v-if="phase?.header || phase?.homeHeader"
              class="flex !flex-row items-center gap-4 text-center text-[9px] font-semibold text-white md:w-auto md:whitespace-nowrap md:text-xs xl:text-base">
              <span v-html="!route.path.includes('/javascript') ? stylize(phase?.header) : stylize(phase?.homeHeader || phase?.header)" />
            </div>
            <div
              v-if="phase?.subtitle"
              class="flex !flex-row items-center gap-4 text-center text-[9px] font-semibold text-white md:w-auto md:whitespace-nowrap md:text-xs xl:text-base">
              <span v-html="phase?.subtitle" />
            </div>
          </div>
          <div class="flex flex-col items-center gap-0 md:flex-row md:gap-2 lg:gap-0">
            <p
              v-if="phase?.preAddendum"
              class="rounded-md text-center text-[8px] font-bold uppercase text-white md:text-[10px] lg:bg-still-yellow/20 lg:px-4 lg:py-1">
              {{ phase?.preAddendum }}
            </p>
            <div
              v-if="phase?.addendum"
              class="text-center text-[9px] font-bold tracking-wide text-white md:text-[12px]  lg:px-4 lg:py-1 lg:text-[12px]"
              style=""
              v-html="phase?.addendum" />
          </div>
          <div
            v-if="phase?.showCountdown"
            class="flex flex-col items-center md:flex-row md:gap-4">
            <span
              v-if="phase?.isExtended"
              class="whitespace-nowrap text-[8px] font-bold uppercase text-still-yellow md:text-[12px] md:tracking-widest xl:mr-9">
              EXTENDED
            </span>
            <span class="text-gradient-primary whitespace-nowrap text-[8px] font-bold uppercase md:text-[9px] md:tracking-widest">
              {{ phase?.countdownFooter }}
            </span>
            <AppCountdown
              v-bind="{ remaining: phase?.remaining, daysToHours: phase?.showDaysAsHours }"
              class="dark"
              box-class="text-white lg:scale-[1.2] lg:translate-x-2 md:px-2 md:py-1 md:bg-still-yellow/30" />
          </div>

          <div
            v-if="phase?.cta"
            class="hidden h-[30px] items-center justify-center rounded-md bg-still-yellow px-4 text-sm font-bold text-eerie-black transition-colors duration-100 ease-linear group-hover:bg-white md:inline-flex">
            {{ phase?.cta }}
          </div>
        </div>
      </div>
    </NuxtLink>
    <button
      class="absolute inset-y-0 my-auto size-5 shrink-0 max-[500px]:relative min-[500px]:right-2 xl:right-3"
      @click="showBanner = false">
      <span class="sr-only">close</span><XMarkIcon class="x-5 h-5 text-white" />
    </button>
  </div>
</template>
