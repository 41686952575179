<script setup>
import { XMarkIcon } from '@heroicons/vue/24/outline'

const now = new Date()
const config = useRuntimeConfig()
const route = useRoute()

const { data } = await useFetch(config.public.BANNER_JSON_URL, {
  query: { affiliate: 'certificates_angular' },
  getCachedData (key) {
    return useNuxtData(key).data.value
  }
})

const phase = data.value?.flat()
  .map((item) => ({
    ...item,
    dateString: item.ends,
    remaining: new Date(item.ends) - now,
    header: stylize(item.header),
    title: stylize(item.title)
  }))
  .find(item => item.remaining > 0)

function stylize (string) {
  return string?.replace('[', '<span class="text-angular-pink">').replace(']', '</span>')
}

const showBanner = useCookie('banner-angular',
  {
    default: () => true,
    watch: 'shallow',
    maxAge: 60 * 60 * 24 * 7,
    SameSite: 'none'
  }
)

const showBannerOnLanding = computed(() => {
  if (route.path.includes('/angular')) {
    return true
  } else {
    return phase.showOnHomepage || false
  }
})
</script>

<template>
  <div class="bg-dark-metal dark:bg-dark-metal/20">
    <div
      v-show="showBanner && phase && showBannerOnLanding"
      class="dark group relative flex h-12 w-full items-center justify-center gap-3 bg-gradient-to-r from-still-red/20 via-purple-dream/20 to-dreem/20 px-2 backdrop-blur-2xl md:gap-5 md:px-6 xl:gap-10"
      :class="{'!from-black !to-black': route.path.includes('angular') }">
      <NuxtLink
        to="/angular#pricing"
        :target="route.path === '/' ? '_blank' : '_self'"
        class="dark flex w-full items-center justify-center gap-2 md:gap-8 xl:gap-10">
        <div
          class="flex items-center gap-2 md:gap-4">
          <InlineSvg
            src="/images/icon-angular-fire.svg"
            hard-code
            class="hidden h-6 w-auto" />
          <div
            class="text-xs font-bold text-white md:block md:text-sm xl:text-base"
            v-html="$route.path === '/' ? stylize(phase?.prelude) : stylize(phase?.homePrelude)" />
        </div>
        <InlineSvg
          src="/images/angular-favicon.svg"
          class="h-7 w-auto" />
        <div class="flex flex-row items-center gap-2 md:flex-row md:gap-8 xl:gap-10">
          <div class="flex flex-col md:flex-row md:items-center md:gap-8 xl:gap-10">
            <!-- <div
            class="whitespace-nowrap text-center text-[12px] font-bold text-white  md:hidden xl:text-base"
            v-html="$route.path === '/' ? stylize(phase?.prelude) : stylize(phase?.homePrelude)" /> -->
            <div
              v-if="phase?.header || phase?.homeHeader"
              class="text-center text-[10px] font-semibold text-white md:block md:w-auto md:whitespace-nowrap md:text-base xl:text-base"
              v-html="$route.path === '/' ? stylize(phase?.header) : stylize(phase?.homeHeader || phase?.header)" />

            <div class="flex flex-row items-center gap-1 md:flex-row md:gap-2 lg:gap-0">
              <p
                v-if="phase?.preAddendum"
                class="rounded-md text-center text-[8px] font-bold uppercase text-white md:bg-[#F921BA]/20 md:px-4 md:py-1 md:text-[10px]">
                {{ phase?.preAddendum }}
              </p>
              <div
                v-if="phase?.addendum"
                class="text-center text-[10px] font-bold text-[#F921BA] md:text-base  lg:px-4 lg:py-1 lg:text-lg"
                style=""
                v-html="phase?.addendum" />
            </div>
          </div>
          <div
            v-if="phase?.showCountdown"
            class="flex flex-col items-center md:flex-row md:gap-4">
            <span
              v-if="phase?.isExtended"
              class="whitespace-nowrap text-[8px] font-bold uppercase text-pink-500 md:text-[12px] md:tracking-widest xl:mr-9">
              EXTENDED
            </span>
            <span class="text-gradient-primary whitespace-nowrap text-[8px] font-bold uppercase md:text-[9px] md:tracking-widest">
              {{ phase?.countdownFooter }}
            </span>
            <AppCountdown
              v-bind="{ remaining: phase?.remaining, daysToHours: phase?.showDaysAsHours }"
              class="dark"
              box-class="text-white lg:scale-[1.2] lg:translate-x-2 md:px-2 md:py-1 md:bg-[#F9218F]/30" />
          </div>
        </div>
      </NuxtLink>
      <button
        class="absolute inset-y-0 my-auto size-5 shrink-0 max-[500px]:relative min-[500px]:right-2 xl:right-3"
        @click="showBanner = false">
        <span class="sr-only">close</span><XMarkIcon class="x-5 h-5 text-white" />
      </button>
    </div>
  </div>
</template>
