<script setup>
import { XMarkIcon } from '@heroicons/vue/24/outline'

const tech = useTech()
const now = new Date()
const config = useRuntimeConfig()
const route = useRoute()

const { data } = await useFetch(config.public.BANNER_JSON_URL, {
  query: { affiliate: 'certificates' },
  getCachedData (key) {
    return useNuxtData(key).data.value
  }
})

const phase = data.value
  ?.flat()
  .map((item) => ({
    ...item,
    dateString: item.ends,
    remaining: new Date(item.ends) - now,
    header: stylize(item.header),
    title: stylize(item.title),
    prelude: stylize(item.prelude),
    subtitle: stylize(item.subtitle),
    appTitle: stylize(item.appTitle)
  }))
  .find((item) => item.remaining > 0)

function stylize (string) {
  return string
    ?.replace(
      '[',
      '<span class="bg-gradient-to-r from-brilliant-rose font-bold to-dodger-blue bg-clip-text text-transparent">'
    )
    .replace(']', '</span>')
}

const user = useUser()
const { list: certifications } = useCertifications()
const hasPurchased = certifications.some(certification => user.accessTo(certification.id, 'EXAM'))

const hasPurchasedExamOnly = computed(() => {
  return !certifications.some(certification => user.accessTo(certification.id, 'TRAINING'))
})

const isBannerClosed = ref(true)

const closeBanner = () => {
  isBannerClosed.value = true
  localStorage.setItem('banner', false)
}

onMounted(() => {
  if (window) {
    const banner = localStorage?.getItem('banner')
    isBannerClosed.value = banner !== null
  }
})

const showBannerOnLanding = computed(() => {
  if (route.path.includes('/vuejs')) {
    return true
  } else {
    return phase.showOnHomepage || false
  }
})

const currentTheme = computed(() => {
  return phase?.theme || 1
})
</script>

<template>
  <NuxtLink
    v-if="!isBannerClosed && (user.isGuest || !hasPurchased || hasPurchasedExamOnly) && phase && showBannerOnLanding"
    :to="phase?.link || '/vuejs#pricing'"
    :target="tech.id === 'vuejs' ? '_self' : '_blank'"
    class="dark group relative flex h-12 w-full items-center justify-center gap-3 px-2 md:gap-5 md:px-4 xl:gap-10"
    :class="{ '!h-16': currentTheme === 2 || currentTheme === 3, '!bg-[linear-gradient(90deg, rgba(66, 85, 255, 0.20)0%, rgba(0, 163, 255, 0.20)100%), #07080E;]' : route.path === '/' }"
    :style="{
      background: phase?.darkMode
        ? '#1b4439'
        : currentTheme === 3 && route.path === '/' ? 'linear-gradient(90deg, rgba(66, 85, 255, 0.20)0%, rgba(0, 163, 255, 0.20)100%)':'linear-gradient(107deg, rgba(248, 100, 189, 0.20) 0%, rgba(71, 133, 254, 0.20) 100%), #252529',
    }">
    <div
      v-if="currentTheme === 1"
      class="flex items-center gap-5 xl:gap-10">
      <div
        v-if="phase?.header"
        class="flex items-center gap-2 md:gap-4">
        <img
          :src="
            phase?.icon
              ? `/images/banner-${phase?.icon}.svg`
              : '/images/banner-winter.svg'
          "
          class="h-4 w-auto md:h-6"
          alt="logo">
        <div
          class="hidden text-xs font-bold text-white md:block xl:text-base"
          v-html="phase?.header" />
      </div>

      <div class="flex items-center gap-3 md:gap-4 xl:gap-8">
        <div class="flex flex-col items-center xl:flex-row xl:gap-6">
          <div
            v-if="phase?.header"
            class="text-[8px] font-bold text-white md:hidden xl:text-base"
            v-html="phase?.header" />
          <div
            v-if="phase?.title"
            class="whitespace-nowrap text-center text-[8px] font-semibold text-white md:text-xs xl:text-base"
            v-html="phase?.title" />

          <div
            v-if="phase?.subtitle"
            class="text-center text-[8px] font-semibold text-white md:text-xs xl:text-base"
            v-html="phase?.subtitle" />
        </div>

        <div
          v-if="phase?.showCountdown"
          class="flex flex-col items-center xl:flex-row xl:gap-4">
          <span
            class="text-gradient-primary whitespace-nowrap text-[8px] font-bold uppercase md:text-xs md:tracking-widest">
            {{ phase?.countdownFooter }}
          </span>
          <AppCountdown
            v-bind="{
              remaining: phase?.remaining,
              daysToHours: phase?.showDaysAsHours,
            }"
            class="dark"
            box-class="text-white p-1 md:px-2 md:py-1"
            :box-style="
              phase?.darkMode
                ? 'background: #176367'
                : 'background: linear-gradient(107deg, rgba(248, 100, 189, 0.50) 0%, rgba(71, 133, 254, 0.50) 100%);'
            " />
        </div>

        <div
          v-if="phase?.addendum"
          class="text-center text-[8px] font-bold text-white md:px-4 md:py-1 xl:text-xs"
          style="letter-spacing: 1.2px">
          {{ phase?.addendum }}
        </div>
      </div>
    </div>
    <div
      v-if="currentTheme === 2"
      class="flex items-center md:gap-5 xl:gap-10">
      <div
        v-if="phase?.header"
        class="hidden text-left text-2xl font-black uppercase text-white md:block [&>span]:!font-black"
        v-html="phase?.header" />
      <div class="flex flex-col items-center xl:flex-row xl:gap-8">
        <div class="flex flex-col items-center whitespace-nowrap xl:space-y-[-4px]">
          <div
            v-if="phase?.header"
            class="text-[8px] font-bold text-white md:hidden md:text-xs xl:text-base"
            v-html="phase?.header" />
          <div
            v-if="phase?.title"
            class="whitespace-nowrap text-center text-[8px] font-semibold text-white md:text-xs xl:text-lg"
            v-html="phase?.title" />

          <div
            v-if="phase?.subtitle"
            class="text-center text-[8px] font-normal text-white md:text-xs xl:text-lg"
            v-html="phase?.subtitle" />
        </div>
        <div class="flex flex-row gap-1 md:flex-col xl:flex-row xl:gap-2">
          <div
            v-if="phase?.addendum"
            class="text-center text-[8px] font-bold text-white md:px-4 md:py-1 md:text-xs xl:text-base"
            style="letter-spacing: 1.2px">
            {{ phase?.addendum }}
          </div>
          <div
            v-if="phase?.showCountdown"
            class="flex flex-row items-center xl:gap-4">
            <span
              class="whitespace-nowrap text-[8px] font-bold uppercase text-brilliant-rose md:text-xs md:tracking-widest xl:text-white">
              {{ phase?.countdownFooter }}
            </span>
            <AppCountdown
              v-bind="{
                remaining: phase?.remaining,
                daysToHours: phase?.showDaysAsHours,
              }"
              class="dark"
              box-class="text-brilliant-rose px-1 md:px-2 xl:py-1 xl:bg-brilliant-rose/10" />
          </div>
        </div>
      </div>
      <button
        v-if="phase?.cta"
        class="hidden h-[30px] items-center justify-center rounded-md bg-brilliant-rose px-4 text-sm font-bold text-eerie-black transition-colors duration-100 ease-linear group-hover:bg-white md:block">
        {{ phase?.cta }}
      </button>
    </div>
    <div
      v-if="currentTheme === 3"
      class="flex items-center gap-4 md:gap-6 xl:gap-6">
      <!-- <InlineSvg
        v-if="route.path === '/'"
        :src="`/images/vuejs/cert-vue.svg`"
        alt="Logo"
        hard-code
        title="Home"
        class="hidden h-16 shrink-0 lg:block" />
      <InlineSvg
        v-if="route.path === '/'"
        :src="`/images/vuejs/vuejs-badge.svg`"
        alt="Logo"
        hard-code
        title="Home"
        class="absolute inset-y-0 left-4 my-auto h-10 shrink-0 md:relative lg:hidden" /> -->
      <div
        class="flex flex-col gap-x-6 gap-y-0 leading-tight xl:flex-row xl:items-center">
        <div
          v-if="phase?.header"
          class="hidden text-left text-2xl font-black uppercase leading-tight text-white md:block [&>span]:!font-black"
          v-html="phase?.header" />
        <div
          v-if="phase?.prelude"
          class="hidden text-left text-xl font-black uppercase leading-tight text-white md:block xl:text-2xl [&>span]:!font-black"
          v-html="phase?.prelude" />
      </div>
      <div class="flex flex-col items-center md:flex-row md:gap-x-8">
        <div
          class="order-1 flex flex-col items-center md:max-w-60 xl:max-w-max xl:space-y-0">
          <div
            v-if="phase?.header || phase?.prelude"
            class="text-[13px] font-bold text-white md:hidden md:text-xs xl:text-base"
            v-html="phase?.header + (phase?.prelude ? '&nbsp;' + phase?.prelude : '')" />
          <div
            v-if="phase?.title || phase?.appTitle"
            class="text-center text-[10px] font-semibold leading-tight text-white md:text-sm xl:text-lg"
            :class="{ '[&>span]:!from-dodger-shine [&>span]:!to-azure-radiance': route.path === '/' }"
            v-html="route.path === '/' ? phase?.appTitle : phase?.title" />

          <div
            v-if="phase?.subtitle"
            class="text-center text-[9px] font-normal text-white md:text-xs xl:text-lg"
            v-html="phase?.subtitle" />
        </div>
        <div
          v-if="phase?.homeCta && $route.path === '/'"
          class="order-4 hidden h-[30px] items-center justify-center rounded-md bg-brilliant-rose px-4 text-sm font-bold text-eerie-black transition-colors duration-100 ease-linear group-hover:bg-white md:inline-flex"
          :class="{ '!bg-dodger-shine !text-white group-hover:!bg-white group-hover:!text-eerie-black': route.path === '/' }">
          {{ phase?.homeCta }}
        </div>
        <div
          v-if="phase?.cta && $route.path !== '/'"
          class="order-4 hidden h-[30px] items-center justify-center rounded-md bg-brilliant-rose px-4 text-sm font-bold text-eerie-black transition-colors duration-100 ease-linear group-hover:bg-white md:inline-flex"
          :class="{ '!bg-dodger-shine !text-white group-hover:!bg-white group-hover:!text-eerie-black': route.path === '/' }">
          {{ phase?.cta }}
        </div>
        <div
          class="order-2 flex flex-col items-center md:max-w-60 xl:max-w-max xl:space-y-0"
          :class="{ 'md:order-3': phase?.invertPosition }">
          <div
            v-if="phase?.homeInterlude && route.path !== '/'"
            class="text-center text-[10px] font-semibold leading-tight text-white md:text-sm xl:text-lg"
            v-html="phase?.homeInterlude" />
          <div
            v-if="phase?.interlude && route.path === '/'"
            class="text-center text-[10px] font-semibold leading-tight text-white md:text-sm xl:text-lg"
            v-html="phase?.interlude" />
        </div>
        <div
          class="order-3 flex flex-row gap-1 md:flex-col xl:flex-row xl:gap-2"
          :class="{ 'md:order-2': phase?.invertPosition }">
          <div class="flex items-center gap-2 md:flex-col md:gap-0 lg:flex-row xl:gap-0">
            <p
              v-if="phase?.preaddendum"
              class="whitespace-nowrap rounded-md text-center text-[9px] font-bold uppercase text-white md:text-[10px] lg:bg-brilliant-rose/20 lg:px-4 lg:py-1"
              :class="{ '!bg-transparent lg:!bg-dodger-shine/20': route.path === '/' }">
              {{ phase?.preaddendum }}
            </p>
            <div
              v-if="phase?.addendum"
              class="whitespace-nowrap text-center text-[9px] font-bold text-white md:px-4 md:py-1 md:text-xs xl:text-base"
              :class="{ '!text-azure-radiance': route.path === '/' }">
              {{ phase?.addendum }}
            </div>
          </div>
          <div
            v-if="phase?.showCountdown"
            class="flex flex-row items-center md:flex-col xl:min-w-[240px] xl:flex-row xl:gap-4">
            <span
              class="whitespace-nowrap rounded-md text-[8px] font-bold uppercase text-white md:text-[10px] md:tracking-widest xl:bg-brilliant-rose/20 xl:px-4 xl:py-1 xl:text-xs xl:text-white"
              :class="{ '!bg-transparent lg:!bg-dodger-shine/20': route.path === '/' }">
              {{ phase?.countdownFooter }}
            </span>
            <AppCountdown
              v-bind="{
                remaining: phase?.remaining,
                daysToHours: phase?.showDaysAsHours,
              }"
              class="dark"
              :box-class="route.path === '/' ? 'text-azure-radiance xl:!text-lg px-1 md:px-2 xl:py-1' : 'text-brilliant-rose xl:!text-lg px-1 md:px-2 xl:py-1'" />
          </div>
        </div>
      </div>
    </div>

    <button
      class="absolute inset-y-0 right-2 my-auto size-5 shrink-0 xl:right-3"
      @click="closeBanner">
      <span class="sr-only">close</span><XMarkIcon class="x-5 h-5 text-white" />
    </button>
  </NuxtLink>
</template>
