// Copied from https://www.lichter.io/articles/nuxt3-sentry-recipe/#nuxt-integration-setting-up-the-client-side
export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const config = useRuntimeConfig()
  const dsn = config.public.SENTRY_DSN

  if (!dsn) return

  const environment = config.public.ENVIRONMENT_NAME

  import('@sentry/vue').then(Sentry => {
    Sentry.init({
      app: nuxtApp.vueApp,
      dsn,
      environment,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false
        })
      ],

      tracesSampleRate: 0.2, // Send 20% of transactions

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /certificates/],

      replaysSessionSampleRate: 0.1, // Send 10% of replays
      replaysOnErrorSampleRate: 0.7 // Send 70% of replays
    })
  })
})
