export default defineNuxtRouteMiddleware(async (to) => {
  const tech = useTech()
  try {
    await tech?.detectTech(to.path, to.params)
  } catch (e) {
    console.error('Error detecting tech:', e)
    throw createError({
      statusCode: 500,
      statusMessage: 'Something went wrong, Please reload the page.',
      data: 'reload'
    })
  }

  if (to.fullPath.startsWith('/javascript/')) {
    const betaTest = useCookie('beta', false)

    if (!betaTest.value && tech?.data?.status === 'COMING_SOON' && to.path !== '/javascript/success') {
      throw createError({ statusCode: 404, statusMessage: 'Page is under construction.' })
    }
  }
})
