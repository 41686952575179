import { find, findLast, findIndex, isEmpty, head, isFunction } from 'lodash-es'

export default function (root, parser = null) {
  const { $api } = useNuxtApp()

  function dummyParser (data) {
    return data
  }

  const parse = isFunction(parser) ? parser : dummyParser

  const list = ref([])

  const isLoading = ref(false)

  async function getOne (predicate, options = {}) {
    try {
      const index = findIndex(list.value, predicate)
      const found = index !== -1

      if (!found || options.fresh) {
        const param = head(Object.values(predicate))

        isLoading.value = true
        const { data } = await $api.get(`${root}/${param}`)
        isLoading.value = false
        if (found && options.fresh) {
          list.value[index] = parse(data)
        } else {
          list.value.push(parse(data))
        }
      }

      return find(list.value, predicate)
    } catch (e) {
      throw createError({
        statusCode: 404
      })
    }
  }

  async function getAll (params = {}, options = {}) {
    let meta = {}
    if (isEmpty(list.value) || options?.fresh) {
      isLoading.value = true
      const response = await $api.get(root, params)
      isLoading.value = false
      response.data.forEach(item => list.value.push(parse(item)))
      meta = response.meta
    }

    return options.includeMeta
      ? {
          list: list.value,
          meta
        }
      : list
  }

  function reset () {
    console.info(`Reseting store ${root}`)
    list.value = []
  }

  function reload () {
    reset()
    return getAll()
  }

  async function firstWhere (filter) {
    await getAll()
    return find(list.value, filter)
  }

  async function lastWhere (filter) {
    await getAll()
    return findLast(list.value, filter)
  }

  return {
    isLoading,
    list,
    getOne,
    getAll,
    reset,
    reload,
    firstWhere,
    lastWhere,
    parse
  }
}
