export default defineNuxtPlugin(nuxtApp => {
  const MAX_RETRIES = 2
  const QUERY_PARAM_NAME = 'nuxt_reload_attempt'

  // Handle "Failed to fetch dynamically imported module ..." or similar issues
  nuxtApp.hook('app:chunkError', async (err) => {
    const [, failedUrl] = /Failed to fetch dynamically imported module: (.*)/.exec(err?.error) || []
    if (failedUrl) {
      //   Try to fetch the URL and check what is the actual problem
      const report = {
        type: 'Failed to fetch dynamically imported module',
        original: err?.error,
        failedUrl
      }
      try {
        const response = await fetch(failedUrl)
        report.response = {
          status: response.status,
          statusText: response.statusText
        }
      } catch (error) {
        report.responseError = error
      }
      report.serverErrorElement = Array
        .from(document.querySelectorAll('h1'))
        .find(el => /Server Error/.test(el.textContent))?.innerHTML

      // Send a report of the problem to Sentry
      console.log({ report })
      import('@sentry/vue').then(Sentry => Sentry.captureException(report))
    }
    const searchParams = new URLSearchParams(window.location.search)
    const currentRetry = Number(searchParams.get(QUERY_PARAM_NAME)) || 0
    if (currentRetry < MAX_RETRIES) {
      console.log('[nuxt]: Reloading due to chunk error')
      searchParams.set(QUERY_PARAM_NAME, (currentRetry + 1).toString())
      // Changing the search also causes a refresh
      window.location.search = searchParams.toString()
    }
  })
})
