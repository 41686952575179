import unhead_KgADcZ0jPj from "/home/runner/work/certificates-app/certificates-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/certificates-app/certificates-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/certificates-app/certificates-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/certificates-app/certificates-app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/certificates-app/certificates-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/certificates-app/certificates-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/certificates-app/certificates-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/certificates-app/certificates-app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/certificates-app/certificates-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_tCgj7NI28J from "/home/runner/work/certificates-app/certificates-app/node_modules/@noemission/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import api_QQzOodE9HA from "/home/runner/work/certificates-app/certificates-app/plugins/api.js";
import chunk_error_handler_wTHYgqOJwi from "/home/runner/work/certificates-app/certificates-app/plugins/chunk-error-handler.js";
import feedbackFish_client_S0o8ePpf28 from "/home/runner/work/certificates-app/certificates-app/plugins/feedbackFish.client.js";
import gtag_client_R70iZRv6jK from "/home/runner/work/certificates-app/certificates-app/plugins/gtag.client.js";
import gtm_client_KEcT5dOoKs from "/home/runner/work/certificates-app/certificates-app/plugins/gtm.client.js";
import helpscout_client_xq2lQEfwLE from "/home/runner/work/certificates-app/certificates-app/plugins/helpscout.client.js";
import mixpanel_flxmgVYaFE from "/home/runner/work/certificates-app/certificates-app/plugins/mixpanel.js";
import prism_client_yDIApfQXmT from "/home/runner/work/certificates-app/certificates-app/plugins/prism.client.js";
import reCaptcha_client_k7ZnKXUFg8 from "/home/runner/work/certificates-app/certificates-app/plugins/reCaptcha.client.js";
import sentry_client_GoGQuZo4Et from "/home/runner/work/certificates-app/certificates-app/plugins/sentry.client.js";
import vueCountdown_client_EohQcN259Q from "/home/runner/work/certificates-app/certificates-app/plugins/vueCountdown.client.js";
import vueInlineSvg_SPJbldAP8W from "/home/runner/work/certificates-app/certificates-app/plugins/vueInlineSvg.js";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt_plugin_tCgj7NI28J,
  api_QQzOodE9HA,
  chunk_error_handler_wTHYgqOJwi,
  feedbackFish_client_S0o8ePpf28,
  gtag_client_R70iZRv6jK,
  gtm_client_KEcT5dOoKs,
  helpscout_client_xq2lQEfwLE,
  mixpanel_flxmgVYaFE,
  prism_client_yDIApfQXmT,
  reCaptcha_client_k7ZnKXUFg8,
  sentry_client_GoGQuZo4Et,
  vueCountdown_client_EohQcN259Q,
  vueInlineSvg_SPJbldAP8W
]