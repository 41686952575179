import { darkThemeNotImplementedTechs } from '~/utils'

export default defineNuxtRouteMiddleware(async (to) => {
  const noLightThemeImplementedTechs = new Set(darkThemeNotImplementedTechs)
  const techPath = to.params.tech || to.path.split('/')[1]

  const isDarkThemeForced = noLightThemeImplementedTechs.has(techPath)
  const isLight = isDarkThemeForced ? null : useCookie('light-theme').value

  useHead({
    htmlAttrs: {
      class: [isLight ? '' : 'dark']
    }
  })
})
